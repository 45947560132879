<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        <span
          v-if="itemInvoiceType"
          class="mr-2"
          v-text="itemInvoiceType.name"
        />
        <span class="font-weight-bold" v-text="value.order_serial" />
        <span class="ml-2" v-text="value.order_number" />
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="sPrice">
      <price-viewer :value="sPrice" :currency="obCurrency" chip label small />
    </v-list-item-action>
  </v-list-item>
</template>

<script lang="ts">
import {
  Invoice,
  InvoiceData,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import { Component, Prop, Vue } from "vue-property-decorator";
import PriceViewer from "@/components/common/PriceViewer.vue";

@Component({
  components: { PriceViewer },
})
export default class InvoicePreview extends Vue {
  @Prop(Object) readonly value!: Partial<InvoiceData> | Invoice;
  @Prop(Boolean) readonly inline!: boolean;

  get itemInvoiceType(): Partial<InvoiceTypeData> | null {
    return this.value instanceof Invoice
      ? this.value.get("invoice_type")
      : this.$_.get(this.value, "invoice_type", null);
  }

  get sPrice() {
    return this.value.total_price_value;
  }

  get obCurrency() {
    return this.value.currency;
  }
}
</script>
